import { Link, LinkProps } from '@reach/router';
import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './Aside.module.scss';
import DataToggle from '../Toggle/DataToggle';
import useViewMode, { hasPermission } from '../../hooks/use-view-mode';

import srcLogo from '../../assets/logotype-white.svg';
import srcCouponActive from '../../assets/icon-sidebar-coupons-active.svg';
import srcCoupon from '../../assets/icon-sidebar-coupons.svg';
import srcOrderActive from '../../assets/icon-sidebar-order-active.svg';
import srcOrder from '../../assets/icon-sidebar-order.svg';
import srcPaymentLinkActive from '../../assets/icon-sidebar-payment-link-active.svg';
import srcPaymentLink from '../../assets/icon-sidebar-payment-link.svg';
import srcPayoutActive from '../../assets/icon-sidebar-payout-active.svg';
import srcPayout from '../../assets/icon-sidebar-payout.svg';
import srcInquiry from '../../assets/icon-sidebar-inquiry.svg';

const SideBarItem = ({
  id,
  src,
  title,
}: {
  id: string;
  src: string;
  title: string;
}) => (
  <>
    <img src={src} width={24} height={24} alt="" />
    <span id={id}>{title}</span>
  </>
);

const SideBarLink = (
  props: React.PropsWithoutRef<LinkProps<{}>> &
    React.RefAttributes<HTMLAnchorElement> & {
      id: string;
      src: string;
      title: string;
      active_src: string;
    }
) => {
  const { id, src, active_src, title } = props;

  return (
    <Link
      {...props}
      getProps={({ isCurrent, isPartiallyCurrent }) => {
        const isActive = isCurrent || isPartiallyCurrent;

        return {
          className: isActive ? styles.active : null,
          children: (
            <SideBarItem
              id={id}
              src={isActive ? active_src : src}
              title={title}
            />
          ),
        };
      }}
    />
  );
};

const Aside = () => {
  const { t } = useTranslation('translation');
  const { base, roles } = useViewMode();

  return (
    <aside className={styles.aside}>
      <Link to="/">
        <img src={srcLogo} height={24} alt="Smartpay" />
      </Link>
      <nav>
        <section className={styles.top}>
          {hasPermission(roles, 'Order') && (
            <SideBarLink
              id="menu_orders"
              to={`${base}/orders`}
              src={srcOrder}
              active_src={srcOrderActive}
              title={t('sidebar-item.orders')}
            />
          )}
          {hasPermission(roles, 'Payout') && (
            <SideBarLink
              id="menu_payouts"
              to={`${base}/payouts`}
              src={srcPayout}
              active_src={srcPayoutActive}
              title={t('sidebar-item.payouts')}
            />
          )}
          {hasPermission(roles, 'Coupon') && (
            <SideBarLink
              id="menu_coupons"
              to={`${base}/coupons`}
              src={srcCoupon}
              active_src={srcCouponActive}
              title={t('sidebar-item.coupons')}
            />
          )}
          {hasPermission(roles, 'PayLink') && (
            <SideBarLink
              id="menu_payment_links"
              to={`${base}/payment-links`}
              src={srcPaymentLink}
              active_src={srcPaymentLinkActive}
              title={t('sidebar-item.payment-links')}
            />
          )}
        </section>
        <section className={styles.bot}>
          <div className={styles.block}>
            <SideBarLink
              id="menu_inquiry"
              to={`${base}/inquiry`}
              src={srcInquiry}
              active_src={srcInquiry}
              title={t('sidebar-item.inquiry')}
            />
          </div>
          <DataToggle className={styles.block} />
        </section>
      </nav>
    </aside>
  );
};

export default Aside;
