import { navigate, RouteComponentProps } from '@reach/router';
import Mixpanel, { useTrackPageView } from '@smartpay/mixpanel';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Capsule from '../../components/Capsule/Capsule';
import Button from '../../components/Form/Button';
import Layout from '../../components/Layout';
import { PAYOUT_DETAIL_SCREEN } from '../../constants';
import {
  usePayoutQuery,
  useLazyPayoutReportDownloadLinkQuery,
  useLazyPayoutStatementDownloadLinkQuery,
} from '../../services/payout';
import {
  formatCurrency,
  formatDateFull,
  formatFractionCurrency,
} from '../../utils/helper';
import styles from './PayoutDetailScreen.module.scss';
import useBankMeta from './use-bank-meta';
import { useAccountQuery } from '../../services/setting';
import Time from '../../components/Time/Time';
import useViewMode from '../../hooks/use-view-mode';

interface PayoutDetailProps extends RouteComponentProps {
  payoutId?: string;
}

const screen = PAYOUT_DETAIL_SCREEN;

const PayoutDetailScreen: FC<PayoutDetailProps> = ({ payoutId = '' }) => {
  useTrackPageView({ screen });

  const { t, i18n } = useTranslation();
  const { data: payout, isLoading } = usePayoutQuery(payoutId);
  const { base } = useViewMode();

  const [fetchPayoutReportDownloadLink] =
    useLazyPayoutReportDownloadLinkQuery();
  const [fetchPayoutStatementDownloadLink] =
    useLazyPayoutStatementDownloadLinkQuery();
  const bankMeta = useBankMeta({ payout });

  const { data: account } = useAccountQuery(undefined, {
    refetchOnMountOrArgChange: false,
  });
  const legalEntity = account?.legalEntity;

  const onDownloadStatement = useCallback(async () => {
    Mixpanel.trackAction({
      screen,
      action: 'Click',
      itemName: 'Download Payout Statement',
    });

    const { data } = await fetchPayoutStatementDownloadLink(payoutId, true);
    const statementDownloadLink = data?.downloadLink;

    if (statementDownloadLink) {
      window.open(statementDownloadLink);
    }
  }, [fetchPayoutStatementDownloadLink, payoutId]);

  const onDownloadReport = useCallback(async () => {
    Mixpanel.trackAction({
      screen,
      action: 'Click',
      itemName: 'Download Payout Report',
    });

    const { data } = await fetchPayoutReportDownloadLink(payoutId, true);
    const reportDownloadLink = data?.downloadLink;

    if (reportDownloadLink) {
      window.open(reportDownloadLink);
    }
  }, [fetchPayoutReportDownloadLink, payoutId]);

  if (!payout) {
    return null;
  }

  return (
    <Layout isLoading={isLoading}>
      <header className={styles.header}>
        <Button
          label={t('back-btn')}
          variant="outline"
          size="small"
          onClick={() => {
            Mixpanel.trackAction({
              screen: PAYOUT_DETAIL_SCREEN,
              action: 'Click',
              itemName: 'Back',
            });

            navigate(`${base}/payouts`);
          }}
          className={styles['btn-back']}
        />
        <nav>
          {/**
           * TODO: Add this back when the endpoint has the information for this
           */}
          {/* <button type="button" className={styles['btn-prev']}>
            left
          </button> */}
          <h2>
            <Time
              dateTime={payout.period.start}
              formatStr={t('format.MMMdd', 'MM/dd')}
            />
            {t('format.periodTo')}
            <Time
              dateTime={payout.period.end}
              formatStr={t('format.MMMdd', 'MM/dd')}
            />
          </h2>
          {/* <button type="button" className={styles['btn-next']}>
            right
          </button> */}
        </nav>
      </header>
      <section className={styles.summary}>
        <div className={styles.payout}>
          <h3>{t('payout.title.payout-overview')}</h3>
          <div className={styles.content}>
            <div className={styles.amount}>
              {formatCurrency(payout?.summary?.totalPayout || 0)}
            </div>
            <div className={styles.meta}>
              <div className={styles.label}>
                {t('payout.label.total-payout')}
              </div>
              <div className={styles.status}>
                <Capsule
                  variant="payment"
                  title={t(`payout.status.${payout.status}`)}
                  status={payout.status}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.transfer}>
          <h3>{t('payout.title.transfer-details')}</h3>
          <div className={styles.content}>
            {!!payout.period.nextPaymentAt && (
              <div className={styles.date}>
                <div className={styles.label}>
                  {t('payout.label.payment-date')}
                </div>
                <time className={styles.value}>
                  {formatDateFull(payout.period.nextPaymentAt, i18n.language)}
                </time>
              </div>
            )}
            {bankMeta && (
              <>
                <div className={styles.bank}>
                  <div className={styles.label}>{t('payout.label.bank')}</div>
                  <div className={styles.value}>{bankMeta.bank}</div>
                </div>
                <div className={styles.branch}>
                  <div className={styles.label}>{t('payout.label.branch')}</div>
                  <div className={styles.value}>{bankMeta.branch}</div>
                </div>
                <div className={styles.account}>
                  <div className={styles.label}>
                    {t('payout.label.account-number')}
                  </div>
                  <div className={styles.value}>{bankMeta.account}</div>
                </div>
              </>
            )}
          </div>
        </div>
      </section>
      {!!payout.summary && (
        <section className={styles.breakdown}>
          <div className={styles.sales}>
            <h3>{t('payout.title.sales-overview')}</h3>
            <div className={styles.content}>
              <div className={styles.total}>
                <div className={styles.value}>
                  {formatCurrency(payout.summary?.payments || 0)}
                </div>
                <div className={styles.label}>
                  {t('payout.label.total-sales')}
                </div>
              </div>
              <div className={styles.refunded}>
                <div className={styles.value}>
                  {formatCurrency(payout.summary.refunds || 0)}
                </div>
                <div className={styles.label}>{t('payout.label.refunds')}</div>
              </div>
            </div>
          </div>
          <div className={styles.fee}>
            <h3>{t('payout.title.fee-details')}</h3>
            <div className={styles.content}>
              <div className={styles.transaction}>
                <div className={styles.value}>
                  {formatFractionCurrency(
                    payout.summary.transactionFeesFixed +
                      payout.summary.transactionFeesRated || 0
                  )}
                </div>
                <div className={styles.label}>
                  {t('payout.label.transaction-fees')}
                </div>
              </div>
              <div className={styles.payment}>
                <div className={styles.value}>
                  {formatCurrency(payout.summary.payoutFee || 0)}
                </div>
                <div className={styles.label}>
                  {t('payout.label.payout-fee')}
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      {payout.status !== 'open' && (
        <section className={styles.report}>
          <div className={styles.separator} />
          <div className={styles['dl-buttons']}>
            {legalEntity && (
              <Button
                onClick={onDownloadStatement}
                label="支払明細書のダウンロード"
                variant="outline"
                className={styles.download}
              />
            )}
            <Button
              onClick={onDownloadReport}
              label="CSVレポートのダウンロード"
              variant="outline"
              className={styles.download}
            />
          </div>
        </section>
      )}
    </Layout>
  );
};

export default PayoutDetailScreen;
