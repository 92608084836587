import { Link, LinkProps } from '@reach/router';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Capsule from '../../components/Capsule/Capsule';
import { useAccountStatus } from '../../utils/helper';
import styles from './SettingNavBar.module.scss';
import useViewMode from '../../hooks/use-view-mode';

const NavLink = (
  props: React.PropsWithoutRef<LinkProps<{}>> &
    React.RefAttributes<HTMLAnchorElement>
) => {
  const { to } = props;
  const { base } = useViewMode();

  return (
    <Link
      {...props}
      to={`${base}${to}`}
      getProps={({ isCurrent }) => {
        return {
          className: isCurrent ? styles.active : null,
        };
      }}
    />
  );
};

const SettingNavBar = () => {
  const { t } = useTranslation('translation');
  const { test: isTestAccount, active: isActiveAccount } = useAccountStatus();

  return (
    <nav className={styles.filters}>
      <NavLink id="link_accounts" to="/settings/accounts">
        {t('settings.navbar.accounts')}
      </NavLink>
      <NavLink id="link_payments" to="/settings/payments">
        {t('settings.navbar.payments')}
      </NavLink>
      <NavLink id="link_credentials" to="/settings/credentials">
        {t('settings.navbar.credentials')}
      </NavLink>
      {isActiveAccount && (
        <Capsule
          id="account-mode"
          title={t('settings.mode.active-mode')}
          variant="mode"
          color="blue"
          data-value="active"
        />
      )}
      {isTestAccount && (
        <Capsule
          id="account-mode"
          title={t('settings.mode.test-mode')}
          variant="mode"
          color="orange"
          data-value="test"
        />
      )}
    </nav>
  );
};

export default SettingNavBar;
