import { RouteComponentProps } from '@reach/router';
import { useTrackPageView } from '@smartpay/mixpanel';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Layout from '../../components/Layout';
import { BRANCHES_SCREEN } from '../../constants';
import BranchRow from './BranchRow';
import Loading from '../../components/Loading';
import { useAccountQuery, useAccountInfoQuery } from '../../services/setting';

import styles from './BranchListScreen.module.scss';
import CorporateNav from '../../components/CorporateNav/CorporateNav';

const screen = BRANCHES_SCREEN;

const BranchListScreen: FC<RouteComponentProps> = () => {
  useTrackPageView({ screen });

  const { t } = useTranslation('translation');

  const { data: account } = useAccountQuery();
  const { data, isFetching, isLoading } = useAccountInfoQuery();

  const branches = data?.merchants;

  return (
    <Layout
      title={`${account?.legalEntity?.name} ${t('corporate.title')}`}
      isLoading={!branches && isLoading}
    >
      <CorporateNav />

      <table className={styles.table}>
        <thead>
          <tr>
            <th className={styles.status}>
              {t('branch-listing.table-header.status')}
            </th>
            <th>{t('branch-listing.table-header.name')}</th>
            <th>{t('branch-listing.table-header.id')}</th>
            <th>{t('branch-listing.table-header.date')}</th>
          </tr>
        </thead>
        <tbody>
          {branches &&
            !isFetching &&
            branches.length > 0 &&
            branches.map((branch) => (
              <BranchRow key={branch.id} branch={branch} />
            ))}
        </tbody>
      </table>
      {isFetching && (
        <div className={styles['loading-wrapper']}>
          <Loading />
        </div>
      )}
    </Layout>
  );
};

export default BranchListScreen;
