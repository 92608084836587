import { Link } from '@reach/router';

import DataToggle from '../Toggle/DataToggle';

import logo from '../../assets/logo-symbol.svg';

import styles from './CorporateAside.module.scss';

const CorporateAside = () => {
  return (
    <aside className={styles.aside}>
      <Link to="/c/branches">
        <img src={logo} width={24} height={24} alt="Smartpay" />
      </Link>
      <nav>
        <section />
        <section className={styles.bot}>
          <DataToggle className={styles.toggler} condensed />
        </section>
      </nav>
    </aside>
  );
};

export default CorporateAside;
