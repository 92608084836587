import { RouteComponentProps } from '@reach/router';
import { useTrackPageView } from '@smartpay/mixpanel';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import useInterval from 'use-interval';
import { NumberParam, useQueryParams } from 'use-query-params';

import IconOrdersActive from '../../assets/icon-sidebar-order-active.svg';
import EmptyStateView from '../../components/EmptyStateView/EmptyStateView';
import Layout from '../../components/Layout';
import PaginationView from '../../components/PaginationView/PaginationView';
import { DEFAULT_PAGE_SIZE, USERS_SCREEN } from '../../constants';
import { useDataMode } from '../../utils/helper';
import UserRow from './UserRow';
import Loading from '../../components/Loading';
import useViewMode from '../../hooks/use-view-mode';
import { useUsersQuery } from '../../services/users';

import styles from './UserListScreen.module.scss';

const screen = USERS_SCREEN;

const UserListScreen: FC<RouteComponentProps> = () => {
  useTrackPageView({ screen });

  const { t } = useTranslation('translation');
  const { merchantId } = useViewMode();
  const { test: isTestDataMode } = useDataMode();

  const [query, setQuery] = useQueryParams({
    offset: NumberParam,
  });
  const offset = query.offset || 0;

  const { data, isLoading, isFetching } = useUsersQuery(
    {
      merchantId,
      offset,
      test: isTestDataMode,
      limit: DEFAULT_PAGE_SIZE,
    },
    {
      refetchOnMountOrArgChange: 60,
    }
  );

  const users = data?.data;

  useInterval(
    () => {
      setQuery({
        offset,
      });
    },
    offset === 0 ? 30000 : null
  ); // refetch every 30 seconds, roughly

  return (
    <Layout title={t('user-listing.title')} isLoading={!users && isLoading}>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>{t('user-listing.table-header.user-email')}</th>
            <th>{t('user-listing.table-header.access')}</th>
            <th>{t('user-listing.table-header.created-at')}</th>
          </tr>
        </thead>
        <tbody>
          {users &&
            !isFetching &&
            users.length > 0 &&
            users.map((user) => <UserRow key={user.id} user={user} />)}
        </tbody>
      </table>
      {isFetching && (
        <div className={styles['loading-wrapper']}>
          <Loading />
        </div>
      )}
      {!isFetching &&
        (users?.length === 0 ? (
          <EmptyStateView
            src={IconOrdersActive}
            title={t('user-listing.search-result.no-users')}
          />
        ) : (
          <PaginationView
            screen={screen}
            hasPrevPage={offset > 0}
            hasNextPage={!!data?.nextOffset}
            onPrevClick={() => {
              setQuery(
                {
                  offset: offset - DEFAULT_PAGE_SIZE,
                },
                'replaceIn'
              );
            }}
            onNextClick={() =>
              setQuery(
                {
                  offset: data?.nextOffset,
                },
                'replaceIn'
              )
            }
          />
        ))}
    </Layout>
  );
};

export default UserListScreen;
