import { Link } from '@reach/router';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import OutsideClickHandler from 'react-outside-click-handler';
import Mixpanel from '@smartpay/mixpanel';
import cx from 'classnames';

import ProfilePopup from '../Popup/ProfilePopup';
import useViewMode from '../../hooks/use-view-mode';

import srcBack from '../../assets/icon-back-squared.svg';
import srcSettings from '../../assets/icon-settings.svg';

import styles from './Header.module.scss';

const Header = ({ className }: { className?: string }) => {
  const { t, i18n } = useTranslation('translation');
  const currentLang = i18n?.language || 'ja';
  const { mode, merchant } = useViewMode();
  const [isShowPopup, setIsShowPopup] = useState(false);

  return (
    <header className={cx(styles.header, className)}>
      {mode === 'corporate-merchant' && (
        <Link to="/c/branches">
          <img
            className={styles['btn-close']}
            src={srcBack}
            alt="Back"
            width={24}
            height={24}
          />
        </Link>
      )}
      {merchant && (
        <>
          <h1>{merchant?.name}</h1>
          <span className={styles.id}>{merchant?.id}</span>
        </>
      )}
      <div className={styles.right}>
        <div>
          <button
            id="btn_profile"
            className={styles.settings}
            type="button"
            onClick={() => setIsShowPopup(true)}
          >
            <img src={srcSettings} alt="" width={24} height={24} />
            {t('header-item.settings')}
          </button>

          {isShowPopup && (
            <OutsideClickHandler
              onOutsideClick={() => {
                Mixpanel.trackAction({
                  action: 'Click',
                  itemName: 'Dismiss',
                });

                if (isShowPopup) {
                  setIsShowPopup(false);
                }
              }}
            >
              <ProfilePopup />
            </OutsideClickHandler>
          )}
        </div>

        <button
          className={styles['lang-switcher']}
          type="button"
          onClick={() =>
            i18n.changeLanguage(currentLang.startsWith('ja') ? 'en' : 'ja')
          }
        >
          {currentLang.startsWith('ja') ? 'English' : '日本語'}
        </button>
      </div>
    </header>
  );
};

export default Header;
