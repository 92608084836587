import { DEFAULT_PAGE_SIZE_PAYMENT_LINK } from '../constants';
import { PaymentLink } from '../types/payment-link';
import baseAPI, { ListResponse, PageParams, TestParams } from './base';

const defaultParams = {
  test: true,
  offset: 0,
  limit: DEFAULT_PAGE_SIZE_PAYMENT_LINK,
};

export interface GetPaymentLinkParams extends TestParams, PageParams {
  merchant?: string;
}

export const PaymentAPI = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    paymentLinks: builder.query<
      ListResponse<PaymentLink>,
      GetPaymentLinkParams
    >({
      query: (params) => {
        return {
          url: `/me/payment-links`,
          params: {
            ...defaultParams,
            ...params,
          },
        };
      },
    }),
    paymentLink: builder.query<PaymentLink, string>({
      query: (paymentLinkId) => ({
        url: `/me/payment-links/${paymentLinkId}`,
      }),
    }),
  }),
  overrideExisting: false,
});

export const { usePaymentLinksQuery, usePaymentLinkQuery } = PaymentAPI;
