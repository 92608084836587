import {
  useApiKeysQuery,
  useBankAccountQuery,
  useFeePlanQuery,
  usePayoutScheduleQuery,
} from '../services/setting';
import useViewMode from './use-view-mode';

export const useMerchantFeePlanQuery = () => {
  const { merchantId } = useViewMode();

  return useFeePlanQuery({ merchantId });
};

export const useMerchantBankAccountQuery = () => {
  const { merchantId } = useViewMode();

  return useBankAccountQuery({ merchantId });
};

export const useMerchantPayoutScheduleQuery = () => {
  const { merchantId } = useViewMode();

  return usePayoutScheduleQuery({ merchantId });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useMerchantApiKeysQuery = (options: any) => {
  const { merchantId } = useViewMode();

  return useApiKeysQuery({ merchantId }, options);
};
