import { useParams } from '@reach/router';
import { useAccountInfoQuery } from '../services/setting';
import { AccountMerchant, Role } from '../types/setting';

type Mode = 'corporate' | 'corporate-merchant' | 'merchant';
type ViewMode = 'corporate' | 'merchant';

interface ViewModeData {
  mode: Mode | undefined;
  viewMode: ViewMode | undefined;
  merchantId?: string;
  merchant?: AccountMerchant;
  roles?: Role[];
  base: string;
}

export const hasPermission = (roles: Role[] = [], permission: string) => {
  if (roles.length === 0) {
    return false;
  }

  if (permission === 'CorporateOwner') {
    return roles.includes('CorporateOwner');
  }

  if (permission === 'CorporateManager') {
    return (
      roles.includes('CorporateOwner') || roles.includes('CorporateManager')
    );
  }

  if (permission === 'Manager') {
    return (
      roles.includes('CorporateOwner') ||
      roles.includes('CorporateManager') ||
      roles.includes('Manager')
    );
  }

  if (
    ['Order', 'Refund', 'Coupon', 'PayLink', 'Payout', 'Setting'].includes(
      permission
    )
  ) {
    if (
      roles.includes('CorporateOwner') ||
      roles.includes('CorporateManager') ||
      roles.includes('Manager')
    ) {
      return true;
    }

    if (roles.find((role) => role.indexOf(permission) === 0)) {
      return true;
    }
  }

  return roles.find((role) => role === permission);
};

const useViewMode = (): ViewModeData => {
  const { merchantId } = useParams();
  const { data: accountInfo } = useAccountInfoQuery();
  const merchants = accountInfo?.merchants || [];
  const merchant =
    merchants.length === 1
      ? merchants[0]
      : merchants.find((mer) => mer.id === merchantId);

  if (!accountInfo) {
    return { mode: undefined, viewMode: undefined, base: '' };
  }

  // corporate merchant
  if (merchantId) {
    return {
      mode: 'corporate-merchant',
      viewMode: 'merchant',
      merchantId: merchantId as string,
      merchant,
      roles: [...accountInfo.corporateAccountRoles, ...(merchant?.roles || [])],
      base: `/m/${merchantId}`,
    };
  }

  // corporate
  if (accountInfo.corporateAccountsFeatureEnabled) {
    return {
      mode: 'corporate',
      viewMode: 'corporate',
      roles: accountInfo.corporateAccountRoles,
      base: '',
    };
  }

  // merchant only
  return {
    mode: 'merchant',
    viewMode: 'merchant',
    merchantId: merchantId as string,
    merchant,
    roles: merchant?.roles,
    base: '',
  };
};

export default useViewMode;
