import { RouteComponentProps } from '@reach/router';
import Mixpanel, { useTrackPageView } from '@smartpay/mixpanel';
import cx from 'classnames';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';

import EmptyStateView from '../../components/EmptyStateView/EmptyStateView';
import Layout from '../../components/Layout';
import PaginationView from '../../components/PaginationView/PaginationView';
import {
  DEFAULT_PAGE_SIZE,
  ORDERS_SCREEN,
  SMARTPAY_DOCUMENT_URL,
} from '../../constants';
import { useCorporateOrdersQuery } from '../../services/order';
import { capitalize, useAccountStatus, useDataMode } from '../../utils/helper';
import OrderRow, { KindIcon } from './OrderRow';
import Loading from '../../components/Loading';
import CorporateNav from '../../components/CorporateNav/CorporateNav';
import { useAccountQuery } from '../../services/setting';
import Popup from '../../components/Popup/Popup';

import IconOrdersActive from '../../assets/icon-sidebar-order-active.svg';
import iconInfoSolidSrc from '../../assets/icon-info-solid.svg';

import styles from './OrderListScreen.module.scss';

export type FilterBy =
  | 'succeeded'
  | 'refunded'
  | 'canceled'
  | 'uncaptured'
  | 'partially_captured';

const screen = ORDERS_SCREEN;

export const getOrderMessage = ({
  status,
  keyword,
}: {
  status?: string;
  keyword?: string;
}) => {
  if (keyword) {
    return 'order-listing.search-result.no-results-found';
  }

  switch (status) {
    case 'succeeded':
      return 'order-listing.search-result.no-succeeded-orders';
    default:
      return 'order-listing.search-result.no-orders';
  }
};

const getStatusesParameters = (statuses: string) => {
  if (statuses === '') {
    return {
      statuses:
        'succeeded,refunded,canceled,requires_capture,partially_captured',
    };
  }

  return {
    statuses: statuses.replace('uncaptured', 'requires_capture'),
    refunded: statuses.includes('refunded'),
  };
};

const CorporateOrderListScreen: FC<RouteComponentProps> = () => {
  useTrackPageView({ screen });

  const { t } = useTranslation('translation');
  const { test: isTestDataMode } = useDataMode();
  const { test: isTestAccount } = useAccountStatus();
  const [showReference, setShowReference] = useState(false);

  const { data: account } = useAccountQuery();
  const [query, setQuery] = useQueryParams({
    offset: NumberParam,
    q: StringParam,
    statuses: StringParam,
  });
  const offset = query.offset || 0;
  const q = query.q || '';
  const statuses = query.statuses || '';

  const { data, isLoading, isFetching } = useCorporateOrdersQuery(
    {
      offset,
      test: isTestDataMode,
      ...(q ? { q } : {}),
      ...getStatusesParameters(statuses),
      limit: DEFAULT_PAGE_SIZE,
    },
    {
      refetchOnMountOrArgChange: 60,
    }
  );

  const orders = data?.data;

  const FilterItem = ({ filterBy }: { filterBy: FilterBy | 'all' }) => {
    return (
      <i
        className={
          statuses.includes(filterBy) || (filterBy === 'all' && statuses === '')
            ? styles.active
            : undefined
        }
        id={`filter_${filterBy}`}
        onClick={() => {
          Mixpanel.trackAction({
            screen,
            action: 'Click',
            itemName: `Filter ${capitalize(filterBy)}`,
          });

          setQuery({
            offset: 0,
            ...(q ? { q } : {}),
            ...(filterBy === 'all' ? { statuses: '' } : { statuses: filterBy }),
          });
        }}
      >
        {t(`order.status.${filterBy}`)}
      </i>
    );
  };

  return (
    <Layout
      title={`${account?.legalEntity?.name} ${t('corporate.title')}`}
      isLoading={!orders && isLoading}
    >
      <CorporateNav />

      <nav className={styles.filters}>
        <FilterItem filterBy="all" />
        <FilterItem filterBy="succeeded" />
        <FilterItem filterBy="refunded" />
        <FilterItem filterBy="canceled" />
        <FilterItem filterBy="uncaptured" />
      </nav>
      <table className={styles.table}>
        <thead>
          <tr>
            <th className={styles.status}>
              {t('order-listing.table-header.status')}
            </th>
            <th className={styles.amount}>
              <div>
                {t('order-listing.table-header.amount')}
                <Popup
                  direction="up"
                  content={
                    <div className={styles['popup-content']}>
                      <div>
                        <KindIcon kind="payment_link" />{' '}
                        {t('order.kind.payment_link')}
                      </div>
                      <div>
                        <KindIcon kind="offline" /> {t('order.kind.offline')}
                      </div>
                    </div>
                  }
                  trigger={
                    <div>
                      <img
                        src={iconInfoSolidSrc}
                        width={16}
                        height={16}
                        alt="info"
                        loading="eager"
                      />
                    </div>
                  }
                />
              </div>
            </th>
            <th className={styles.amount}>
              {t('order-listing.table-header.discount-amount')}
            </th>
            <th>
              <div
                className={cx(
                  styles['ref-switch'],
                  showReference ? styles['show-ref'] : styles['show-id']
                )}
                onClick={() => setShowReference(!showReference)}
              >
                <span className={styles.id}>
                  {t('order-listing.table-header.id')}
                </span>
                <span className={styles.ref}>
                  {t('order-listing.table-header.reference')}
                </span>
              </div>
            </th>
            <th>{t('order-listing.table-header.consumer-email')}</th>
            <th>{t('order-listing.table-header.date')}</th>
          </tr>
        </thead>
        <tbody>
          {orders &&
            !isFetching &&
            orders.length > 0 &&
            orders.map((order) => (
              <OrderRow
                key={order.id}
                order={order}
                showReference={showReference}
              />
            ))}
        </tbody>
      </table>
      {isFetching && (
        <div className={styles['loading-wrapper']}>
          <Loading />
        </div>
      )}
      {!isFetching &&
        (orders?.length === 0 ? (
          <EmptyStateView
            src={IconOrdersActive}
            title={t(getOrderMessage({ keyword: q, status: statuses }))}
          >
            {isTestAccount && (
              <>
                <span>{t('order-listing.empty-state.title')}</span>
                <a href={SMARTPAY_DOCUMENT_URL}>{t('doc-url-desc')}</a>
              </>
            )}
          </EmptyStateView>
        ) : (
          <PaginationView
            screen={screen}
            hasPrevPage={offset > 0}
            hasNextPage={!!data?.nextOffset}
            onPrevClick={() => {
              setQuery(
                {
                  offset: offset - DEFAULT_PAGE_SIZE,
                },
                'replaceIn'
              );
            }}
            onNextClick={() =>
              setQuery(
                {
                  offset: data?.nextOffset,
                },
                'replaceIn'
              )
            }
          />
        ))}
    </Layout>
  );
};

export default CorporateOrderListScreen;
