import { Order, PaymentTransaction } from '../types/order';
import baseAPI, { ListResponse, PageParams, TestParams } from './base';

const defaultParams = {
  test: true,
};

export interface GetOrdersParams extends TestParams, PageParams {
  merchant?: string;
  search?: string;
  statuses?: string;
  promotionCode?: string;
}

export const orderAPI = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    orders: builder.query<ListResponse<Order>, GetOrdersParams>({
      query: (orderParams) => {
        return {
          url: `/me/merchant/orders`,
          params: {
            ...defaultParams,
            ...orderParams,
          },
        };
      },
    }),
    corporateOrders: builder.query<ListResponse<Order>, GetOrdersParams>({
      query: (orderParams) => {
        return {
          url: `/me/corporate-account/orders`,
          params: {
            ...defaultParams,
            ...orderParams,
          },
        };
      },
    }),
    order: builder.query<Order, string>({
      query: (orderId) => ({
        url: `/me/orders/${orderId}`,
      }),
    }),
    transactions: builder.query<PaymentTransaction[], string>({
      query: (orderId) => ({
        url: `/me/transactions?order=${orderId}`,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useOrdersQuery,
  useCorporateOrdersQuery,
  useOrderQuery,
  useTransactionsQuery,
} = orderAPI;
