import { User } from '../types/user';
import baseAPI, { ListResponse, PageParams, TestParams } from './base';

const defaultParams = {
  test: true,
};

export interface GetUsersParams extends TestParams, PageParams {
  merchantId?: string;
}

export const userAPI = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    users: builder.query<ListResponse<User>, GetUsersParams>({
      query: (params) => {
        return {
          url: `/me/merchant/users`,
          params: {
            ...defaultParams,
            ...params,
          },
        };
      },
    }),
    corporateUsers: builder.query<ListResponse<User>, GetUsersParams>({
      query: (params) => {
        return {
          url: `/me/corporate-account/users`,
          params: {
            ...defaultParams,
            ...params,
          },
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const { useUsersQuery, useCorporateUsersQuery } = userAPI;
