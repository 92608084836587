import { navigate } from '@reach/router';
import Mixpanel from '@smartpay/mixpanel';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import Capsule from '../../components/Capsule/Capsule';
import { PAYOUTS_SCREEN } from '../../constants';
import { Payout } from '../../redux/payout';
import { formatCurrency } from '../../utils/helper';
import styles from './PayoutRow.module.scss';
import Time from '../../components/Time/Time';
import useViewMode from '../../hooks/use-view-mode';

const PayoutRow = ({ payout }: { payout: Payout }) => {
  const { t } = useTranslation('translation');
  const { base } = useViewMode();

  return (
    <tr
      className={styles.payout}
      onClick={() => {
        Mixpanel.trackAction({
          screen: PAYOUTS_SCREEN,
          action: 'Click',
          itemName: 'Payout Item',
        });
        navigate(`${base}/payouts/${payout.id}`);
      }}
    >
      <td>
        <span className={styles.period}>
          <Time
            dateTime={payout.period.start}
            formatStr={t('format.MMMdd', 'MM/dd')}
          />
          {t('format.periodTo')}
          <Time
            dateTime={payout.period.end}
            formatStr={t('format.MMMdd', 'MM/dd')}
          />
        </span>
      </td>
      <td>
        <Capsule
          variant="payment"
          title={t(`payout.status.${payout.status}`)}
          status={payout.status}
        />
      </td>
      <td
        className={cx(
          styles.amount,
          payout.amount && payout.amount < 0 && styles.negative
        )}
      >
        {payout.amount && payout.currency
          ? formatCurrency(payout.amount, payout.currency)
          : '--'}
      </td>
    </tr>
  );
};

export default PayoutRow;
