import { configureStore } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import rootReducer from '.';
import baseAPI from '../services/base';

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  actionTransformer: (action) => {
    if (action.type === 'auth/login') {
      // Return null to not log the action to Sentry
      return null;
    }

    return action;
  },
});

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  blacklist: ['misc', 'payout', 'settingApiKeys', 'settingAccounts'],
};

export const persistedRootReducer = persistReducer(persistConfig, rootReducer);

const configuredStore = () => {
  const store = configureStore({
    reducer: persistedRootReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }).concat(baseAPI.middleware),
    enhancers:
      !!process.env.REACT_APP_SKIP_SENTRY_INIT ||
      !window.location.host.startsWith('checkout.smartpay.co')
        ? []
        : [sentryReduxEnhancer],
  });

  const persistor = persistStore(store);

  return { store, persistor };
};

export default configuredStore;
