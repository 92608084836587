import { Link, LinkProps } from '@reach/router';

import { useTranslation } from 'react-i18next';

import styles from './CorporateNav.module.scss';
import { ReactNode } from 'react';
import useViewMode, { hasPermission } from '../../hooks/use-view-mode';

const CorporateNavLink = (
  props: React.PropsWithoutRef<LinkProps<{}>> &
    React.RefAttributes<HTMLAnchorElement> & {}
) => {
  return (
    <Link
      {...props}
      getProps={({ isCurrent, isPartiallyCurrent }) => {
        return isCurrent || isPartiallyCurrent
          ? { className: styles.active }
          : {};
      }}
    />
  );
};

const CorporateNav = ({ actions }: { actions?: ReactNode }) => {
  const { t } = useTranslation('translation');
  const { roles } = useViewMode();

  return (
    <nav className={styles.nav}>
      <CorporateNavLink to="/c/branches">
        {t('corporate-item.branches')}
      </CorporateNavLink>
      <CorporateNavLink to="/c/account-info">
        {t('corporate-item.account-info')}
      </CorporateNavLink>
      <CorporateNavLink to="/c/payout-info">
        {t('corporate-item.payout-info')}
      </CorporateNavLink>
      {hasPermission(roles, 'Order') && (
        <CorporateNavLink to="/c/orders">
          {t('sidebar-item.orders')}
        </CorporateNavLink>
      )}
      {hasPermission(roles, 'Payout') && (
        <CorporateNavLink to="/c/payouts">
          {t('sidebar-item.payouts')}
        </CorporateNavLink>
      )}
      {hasPermission(roles, 'Coupon') && (
        <CorporateNavLink to="/c/coupons">
          {t('sidebar-item.coupons')}
        </CorporateNavLink>
      )}
      <CorporateNavLink to="/c/users">
        {t('corporate-item.users')}
      </CorporateNavLink>
      <div className={styles.right}>{actions}</div>
    </nav>
  );
};

export default CorporateNav;
