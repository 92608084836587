import { AnyAction, combineReducers, Reducer } from 'redux';
import auth from './auth';
import misc from './misc';
import payout from './payout';
import settingAccounts from './setting-accounts';
import settingApiKeys from './setting-api-keys';
import settingPayments from './setting-payments';
import baseAPI from '../services/base';

const appReducer = combineReducers({
  auth,
  payout,
  settingAccounts,
  settingPayments,
  settingApiKeys,
  misc,
  [baseAPI.reducerPath]: baseAPI.reducer,
});

type RootBaseState = ReturnType<typeof appReducer>;

const rootReducer: Reducer<RootBaseState, AnyAction> = (state, action) => {
  if (action.type === 'RESET') {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export const resetStore = () => ({
  type: 'RESET',
});

export default rootReducer;
