import { navigate } from '@reach/router';
import Mixpanel from '@smartpay/mixpanel';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import Capsule from '../../components/Capsule/Capsule';
import { ORDERS_SCREEN } from '../../constants';
import { Order } from '../../types/order';
import { formatCurrency } from '../../utils/helper';
import iconPaymentLinkSrc from '../../assets/icon-payment-link.svg';
import iconOfflineSrc from '../../assets/icon-offline.svg';
import styles from './OrderRow.module.scss';
import sponsoredSrc from '../../assets/icon-sponsored.svg';
import Time from '../../components/Time/Time';
import useViewMode from '../../hooks/use-view-mode';

export const KindIcon = ({
  kind,
}: {
  kind: 'online' | 'offline' | 'payment_link' | 'token' | undefined;
}) => {
  switch (kind) {
    case 'offline':
      return (
        <img
          src={iconOfflineSrc}
          width={16}
          height={16}
          alt="offline"
          loading="eager"
        />
      );

    case 'payment_link':
      return (
        <img
          src={iconPaymentLinkSrc}
          width={16}
          height={16}
          alt="payment link"
          loading="eager"
        />
      );

    default:
      return <span className={styles['kind-placeholder']} />;
  }
};

const OrderRow = ({
  order,
  showReference = false,
}: {
  order: Order;
  showReference?: boolean;
}) => {
  const { base } = useViewMode();
  const { t } = useTranslation('translation');

  return (
    <tr
      className={styles.order}
      onClick={() => {
        Mixpanel.trackAction({
          screen: ORDERS_SCREEN,
          action: 'Click',
          itemName: 'Order',
        });
        navigate(`${base}/orders/${order.id}`);
      }}
    >
      <td>
        <Capsule
          variant="order"
          title={t(`order.status.${order.displayStatus}`)}
          status={order.displayStatus}
        />
      </td>
      <td className={cx(styles.amount)}>
        <div>
          {formatCurrency(order.amount, order.currency)}
          <KindIcon kind={order.checkoutKind} />
        </div>
      </td>
      <td className={cx(styles.amount, styles.discount)}>
        {order.discountAmount > 0 && (
          <>
            {order.sponsored && (
              <img width="18" height="18" alt="" src={sponsoredSrc} />
            )}
            {formatCurrency(order.discountAmount, order.currency)}
          </>
        )}
      </td>
      <td className={styles.mono}>
        {showReference ? order?.reference : order.id}
      </td>
      <td>{order?.customerEmail}</td>
      <td>
        <Time
          dateTime={order.createdAt}
          onEmpty="ー"
          formatDateStr="y/MM/dd"
          formatTimeStr="HH:mm"
        />
      </td>
    </tr>
  );
};

export default OrderRow;
