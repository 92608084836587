import classNames from 'classnames';
import Time from '../../components/Time/Time';
import { User } from '../../types/user';

import styles from './UserRow.module.scss';
import { useTranslation } from 'react-i18next';

const UserRow = ({ user }: { user: User }) => {
  const { t } = useTranslation('translation');

  return (
    <tr className={styles.user}>
      <td>{user.username}</td>
      <td>
        <div className={styles.roles}>
          {user.roles.map((role) => (
            <span
              key={role}
              className={classNames(styles.role, styles[role])}
              title={t(`user-listing.permission-alt.${role}`)}
            >
              {t(`user-listing.permission.${role}`)}
            </span>
          ))}
        </div>
      </td>
      <td>
        <Time
          dateTime={user.createdAt}
          onEmpty="ー"
          formatDateStr="y/MM/dd"
          formatTimeStr="HH:mm"
        />
      </td>
    </tr>
  );
};

export default UserRow;
